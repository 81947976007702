<template>
  <div>{{ saved_tid }}</div>
</template>

<script>

import {setCookie} from "@/helper/CookieHelper";
import {getTimeStampNow} from "@/helper/TimeHelper";

export default {
  name: "ReferralTracking",
  data() {

    return {
      "saved_tid": null
    }
  },
  methods: {},
  async mounted() {
    const tid = this.$route.params['tid']
    if (!tid) {
      return
    }
    this.saved_tid = tid + '.' + getTimeStampNow().toString()
    setCookie('market_tid', this.saved_tid)
    await this.$router.push({name: 'Home'})

  }
}
</script>

<style scoped>

</style>
