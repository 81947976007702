const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const getTimeStampNow = () => {
  return new Date().getTime() / 1000 | 0
}

/**
 * call callback when timeOut is smaller than duration time for last call
 * @param callback
 * @param timeOut
 * @return function
 */
const debounce = function (callback, timeOut = 100) {
  let timeOutId
  return (...args) => {
    clearTimeout(timeOutId)
    timeOutId = setTimeout(() => {
      callback.apply(this, args)
    }, timeOut)
  }
}

/**
 * call callback exactly one per delay
 * @param callback
 * @param delay
 * @return function
 */
const throttle = (callback, delay = 100) => {
  let lastCalled = 0;
  return (...args) => {
    let now = new Date().getTime();
    if(now - lastCalled < delay) {
      return;
    }
    lastCalled = now;
    return callback(...args);
  }
}

export {
  sleep,
  getTimeStampNow,
  debounce,
  throttle,
}
